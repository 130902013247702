import React from "react";
import Hero from "../../components/HomeComponents/HeroSection/hero";
import Services from "../../components/HomeComponents/ServicesSection/services";
import Portfolio from "../../components/HomeComponents/PortfolioSection/portfolio";
import Testimonial from "../../components/HomeComponents/TestimonialSection/testimonial";
import Contact from "../../components/HomeComponents/ContactSection/contact";

import { Container } from "react-bootstrap";

const Home = ({ user, services, portfolio, testimonial }) => {
  return (
    <div>
      <Hero user={user} />
      <Container>
        <Services services={services} />
        <Portfolio portfolio={portfolio} />
        <Testimonial testimonial={testimonial} />
        <Contact />
      </Container>
    </div>
  );
};

export default Home;
