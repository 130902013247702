import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { postContact } from "../../../api/services/services";

import "./contact.css";

const Contact = () => {
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    postContact(inputs);
  };

  return (
    <div className="ContactForm">
      <h2 className="text-center py-4">Contact</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="name"
            name="name"
            value={inputs.name || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            value={inputs.email || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupSubject">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="subject"
            name="subject"
            value={inputs.subject || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupMessage">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Leave a message here"
            style={{ height: "150px" }}
            name="message"
            value={inputs.message || ""}
            onChange={handleChange}
          />
        </Form.Group>
        <div className="d-grid gap-2">
          <Button variant="success" size="lg" type="submit">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Contact;
