import React from "react";
import "./services.css";
import { Col, Row, Container } from "react-bootstrap";
import config from "../../../api/config/config";

const Services = ({ services }) => {
  return (
    <div className="main Services">
      <h2 className="text-center py-4">Services</h2>
      <Row>
        {services.map((service, index) => (
          <Col lg={4} md={4} sm={1}>
            <div className="text-center servicesContainer">
              <img src={config.media + service.image} className="iconSize" />
              <h4 className="heading py-2">{service.title}</h4>
              <hr />
              <span className="lead">
                <em>{service.description}</em>
              </span>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Services;
