import React from "react";
import "./hero.css";
import Image from "../../../images/undraw_Projections_re_ulc6.png";
import { Col, Row } from "react-bootstrap";

const Hero = ({ user }) => {
  return (
    <Row className="rowStyle Home">
      <Col lg={2} md={2} sm={1}>
        <div className="intro">
          <h3 className="title">{user.designation}</h3>
          <hr />
          <span className="paragraph">{user.bio}</span>
        </div>
      </Col>
      <Col lg={2} md={2} sm={1}>
        <img src={Image} className="imageStyle" />
      </Col>
    </Row>
  );
};

export default Hero;
