import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import Header from "./components/header/header.jsx";
import Footer from "./components/footer/footer.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import { onStartApp } from "./utils";

function App() {
  const [load, setLoad] = useState(true);
  const [user, setUser] = useState({});
  const [services, setServices] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    onStartApp(setLoad, setUser, setServices, setPortfolio, setTestimonial);
  }, []);
  return (
    <Router>
      {load ? (
        <div>
          <p className="loading">Loading . . .</p>
        </div>
      ) : (
        <div>
          <Header user={user} />
          <Routes>
            <Route
              path="/"
              exact
              element={
                <Home
                  user={user}
                  services={services}
                  portfolio={portfolio}
                  testimonial={testimonial}
                />
              }
            />
          </Routes>
          <Footer user={user} services={services} />
        </div>
      )}
    </Router>
  );
}

export default App;
