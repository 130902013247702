import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.css";

const customClass = "link";

const Footer = ({ user, services }) => {
  return (
    <div className="footer Contact">
      <Container>
        <Row className="stylingFooter py-5">
          <Col lg={4} md={4} sm={1}>
            <div>
              <h3>{user.name}</h3>
              <hr />
              <span className="text">{user.bio}</span>
            </div>
          </Col>

          <Col lg={4} md={4} sm={1}>
            <div className="footerServices">
              <h3>Services</h3>
              <ul className="text">
                {services.map((service, index) => (
                  <li>{service.title}</li>
                ))}
              </ul>
            </div>
          </Col>

          <Col lg={4} md={4} sm={1}>
            <div className="footerServices">
              <h3>Contact</h3>
              <span className="text">
                <b>Email: </b> {user.email}
              </span>

              <div className="text">
                <b>Phone number:</b> {user.phoneNumber}
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="text-center pb-4">
          <span className="copy">Copyright &copy; 2023</span>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
