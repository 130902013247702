import directoryApi from "../config/directoryApi";

export const getUser = async (setUser) => {
  let response = await directoryApi.getRequest("user/");
  if (response.success) {
    setUser(response.data.user);
  } else {
    console.log(response, "false");
  }
};

export const getServices = async (setServices) => {
  let response = await directoryApi.getRequest("services/");
  if (response.success) {
    // console.log(user.data, "true");
    setServices(response.data.services);
  } else {
    console.log(response, "false");
  }
};

export const getPortfolio = async (setPortfolio) => {
  let response = await directoryApi.getRequest("portfolio/");
  if (response.success) {
    setPortfolio(response.data.portfolio);
  } else {
    console.log(response, "false");
  }
};

export const getTestimonial = async (setTestimonial) => {
  let response = await directoryApi.getRequest("testimonial/");
  if (response.success) {
    setTestimonial(response.data.testimonial);
  } else {
    console.log(response, "false");
  }
};

export const postContact = async (data) => {
  //   console.log(data, "from services");
  let apiData = {
    name: data.name,
    emailAddress: data.email,
    subject: data.subject,
    message: data.message,
  };
  let response = await directoryApi.postRequest("contact/addContact/", null, {
    apiData,
  });
  console.log(response, "response from contact");
};
