import React from "react";
import { Link } from "react-scroll";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./header.css";

const customClass = "link";

const Header = ({ user }) => {
  const headerList = [
    "Home",
    "Services",
    "Portfolio",
    "Testimonial",
    "Contact",
  ];
  return (
    <Navbar className="page py-4" expand="lg">
      <Container>
        <Navbar.Brand href="#home" className="name">
          {user.name}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navLinks ms-auto">
            {headerList.map((label, index) => (
              <Link to={label} spy={true} className={customClass}>
                {label}
              </Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
