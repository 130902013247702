import React from "react";
import "./portfolio.css";
import { Col, Row, Container } from "react-bootstrap";
import config from "../../../api/config/config";

const Portfolio = ({ portfolio }) => {
  return (
    <div className="main Portfolio">
      <h2 className="text-center py-4">Portfolio</h2>

      <Row>
        {portfolio.map((portfolio, index) => (
          <Col lg={4} md={4} sm={1}>
            <div className="text-center">
              <img src={config.media + portfolio.image} className="imageSize" />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Portfolio;
