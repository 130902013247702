import {
  getUser,
  getServices,
  getPortfolio,
  getTestimonial,
} from "./api/services/services";

export const onStartApp = (
  setLoad,
  setUser,
  setServices,
  setPortfolio,
  setTestimonial
) => {
  getUser(setUser);
  getServices(setServices);
  getPortfolio(setPortfolio);
  getTestimonial(setTestimonial);
  setLoad(false);
};
