import React from "react";
import "./testimonial.css";
import { Col, Row, Carousel } from "react-bootstrap";
import config from "../../../api/config/config";

const Testimonial = ({ testimonial }) => {
  return (
    <div className="Testimonial main">
      <h2 className="text-center py-4">Testimonials</h2>

      <Carousel variant="dark">
        {testimonial.map((testimonial, index) => (
          <Carousel.Item>
            <div className="design">
              <Row>
                <Col lg={4} md={4} sm={1}>
                  <img
                    className="d-block w-100"
                    src={config.media + testimonial.image}
                  />
                </Col>

                <Col lg={8} md={8} sm={1}>
                  <p>
                    {'"' + testimonial.review + '"'}
                    <br />
                    <br />- <b>{testimonial.name}</b>
                  </p>
                </Col>
              </Row>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonial;
