import axios from "axios";
import config from "./config";

const instance = axios.create({
  baseURL: config.api,
  headers: {
    "Content-Type": "application/json",
  },
});

export default {
  //Type of inheritance and resuability of code just need to add endpoint
  getRequest: async (path) => {
    let response = await instance.get(path).catch((error) => {
      if (error.response) {
        return {
          success: false,
          status: error.response.status,
          data: error.response.data,
        };
      } else {
        return {
          success: false,
          status: -1,
          data: {},
        };
      }
    });
    if (response.status == 200) {
      return {
        success: true,
        status: response.status,
        data: response.data,
      };
    } else {
      return {
        success: false,
        status: response.status,
        data: response.data,
      };
    }
  },
  postRequest: async (path, data, params) => {
    let response = await instance.post(path, data, params).catch((error) => {
      if (error.response) {
        return {
          success: false,
          status: error.response.status,
          data: error.response.data,
        };
      } else {
        return {
          success: false,
          status: -1,
          data: {},
        };
      }
    });
    if (response.status == 200) {
      return {
        success: true,
        status: response.status,
        data: response.data,
      };
    } else {
      return {
        success: false,
        status: response.status,
        data: response.data,
      };
    }
  },
};
